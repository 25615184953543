<template>
  <div>
    <v-container>
      <v-radio-group v-model="selected">
        <v-row class="rounded-sm">
          <h5>Chọn kiểu khóa học!</h5>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="4" class="justify-center text-center">
            <v-radio label="Bài thực hành" :value="1"></v-radio>
            <v-img
                height="360"
                src="@/assets/images/Screenshot from 2020-09-19 10-11-49.png"
            ></v-img>
          </v-col>
          <v-col cols="4">
            <v-radio label="Bài giảng" :value="3"></v-radio>
            <v-img
                height="360"
                src="@/assets/images/Screenshot from 2020-09-19 10-12-32.png"
            ></v-img>
          </v-col>
        </v-row>
      </v-radio-group>
      <v-row>
        <v-col class="justify-center text-center">
          <div class="validate-error text-danger text-danger">
            {{ validate_message }}
          </div>
          <v-btn @click="saveType">Tiếp tục</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {UPDATE_LESSON_TYPE, GET_LESSON_DETAIL} from "@/store/lessons.module";
import {GET_COURSE_DETAIL} from "@/store/courses.module";

export default {
  data: () => ({
    selected: [],
    lesson_id: -1,
    validate_message: ""
  }),
  computed: {
    ...mapState({
      lesson: (state) => state.lessons.lesson,
      course: (state) => state.courses.detailCourse,
    }),
  },
  mounted() {
    this.course_slug = this.$route.query.course_slug;
    this.lesson_slug = this.$route.query.lesson_slug;
    this.$store.dispatch(GET_LESSON_DETAIL, {lesson_slug: this.lesson_slug});
    this.$store.dispatch(GET_COURSE_DETAIL, {course_slug: this.course_slug});
  },
  methods: {
    redirectPage(lesson_type, lesson_slug) {
      if (lesson_type === 3) {
        this.$router.push({
          path: "/lesson/video",
          query: {lesson_slug: lesson_slug, course_slug: this.course.slug},
        });
      }
      if (lesson_type === 1) {
        this.$router.push({
          path: "/exercise/select",
          query: {lesson_slug: lesson_slug, lesson_id: this.lesson.id, course_slug: this.course.slug}
        });
      }
    },
    saveType() {
      if (this.selected.length === 0) {
        this.validate_message = "Bạn chưa chọn loại bài học"
        return null;
      }
      this.$store
          .dispatch(UPDATE_LESSON_TYPE, {
            type: this.selected,
            lesson_id: this.lesson.id,
            lesson_slug: this.lesson.slug
          })
          .then(() => {
            this.redirectPage(this.selected, this.lesson.slug);
          });
    },
  },
};
</script>
